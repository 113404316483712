import {
  DateInput,
  useBetaForm,
  useDateHelpers,
  BlueButton,
  Heading,
  BaseInput as TextInput,
  SessionMessageWrapper,
  BlueOutlineButton,
  FormHeading,
  Paragraph,
  AppRedirectionWrapper,
} from "ui";
import Link from "next/link";
import {
  fetcher,
  usersRedirectForLoginWithGoogle,
  // accessLiveness,
} from "services";
import { useToken } from "ui";
import { logger } from "utils";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setVerifyUsername, setAlertMessage } from "store";
import { useRouter } from "next/router";
import { useEffect } from "react";
import CookieWarning from "../components/cookie";

export default function Login() {
  const dispatch = useDispatch();
  const router = useRouter();

  const { redirectAppUrl } = useSelector(
    ({ auth }) => ({
      redirectAppUrl: auth.redirectAppUrl,
    }),
    shallowEqual
  );

  const { mutateToken, token } = useToken({
    redirectTo: redirectAppUrl ? redirectAppUrl : "/",
    redirectIfFound: true,
  });

  const loginForm = useBetaForm({
    type: "email",
    password: "",
    value: "",
  });

  useEffect(() => {
    console.log("LOGIN_MOUNTED");
    (async () => {
      try {
        // const livenessResponse = await 'accessLiveness'();
        // console.log("livenessResponse-response", livenessResponse);
      } catch (error) {
        console.log("liveness-error", error);
      }
    })();
  }, []);

  const submitForm = async (event) => {
    event.preventDefault();

    try {
      loginForm.startProcessing();

      mutateToken(
        await fetcher("/api/login", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(loginForm.value),
        })
      );

      loginForm.finishProcessing();
    } catch (errors) {
      logger("errors in login", errors.data);

      if (Object.keys(errors.data).includes("AttributeNotVerified")) {
        dispatch(setAlertMessage("Please check your email for OTP and verify"));
        dispatch(setVerifyUsername(loginForm.value.value));
        router.push("/verify");
      }

      loginForm.setErrors(errors.data);
      loginForm.finishProcessing();
    }
  };

  return (
    <AppRedirectionWrapper>
      <SessionMessageWrapper>
        <div className="flex min-h-screen bg-white">
          <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="w-full max-w-sm mx-auto lg:w-96">
              <div>
                <FormHeading>Sign In</FormHeading>
                <Paragraph>Welcome back! Please signin to continue.</Paragraph>
              </div>

              <div className="mt-8">
                <div className="mt-6">
                  <form onSubmit={submitForm} className="space-y-6">
                    <div>
                      <TextInput
                        id="email"
                        label="Email Address"
                        onChange={(v) => {
                          loginForm.setField("value", v);
                        }}
                        defaultValue={loginForm.getField("value")}
                        errorText={loginForm.errors.get("value")}
                        placeholder="Enter email"
                      />
                    </div>

                    <div className="space-y-1">
                      <TextInput
                        id="password"
                        type="password"
                        label="Password"
                        onChange={(v) => {
                          loginForm.setField("password", v);
                        }}
                        defaultValue={loginForm.getField("password")}
                        errorText={loginForm.errors.get("password")}
                        placeholder="Enter password"
                        labelCorner={() => {
                          return (
                            <Link href={"/forgot-password"}>
                              <a className="text-sm cursor-pointer text-blue-500 hover:text-blue-600">
                                Forgot your password?
                              </a>
                            </Link>
                          );
                        }}
                      />
                    </div>

                    <div>
                      <BlueButton
                        type="submit"
                        disable={loginForm.busy}
                        busy={loginForm.busy}
                        busyText={"Signing In.."}
                        widthClasses="w-full"
                      >
                        Sign in
                      </BlueButton>
                    </div>
                  </form>

                  <div className="relative my-4">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="px-2 bg-white text-sm text-gray-500">
                        Or
                      </span>
                    </div>
                  </div>

                  <div className="mb-6 w-full">
                    <BlueOutlineButton
                      busy={loginForm.busy}
                      onClick={async () => {
                        const redirect_to = `${window.location.protocol}//${window.location.host}/login-with-google`;
                        let response = await usersRedirectForLoginWithGoogle({
                          redirect_to,
                        });
                        window.location.href = response.redirect_to;
                      }}
                      widthClasses="w-full"
                    >
                      Sign in with Google
                    </BlueOutlineButton>
                  </div>

                  <p className="mt-2 text-xs">
                    Don&apos;t have an account?
                    <Link href={"/register"}>
                      <a className="ml-1 text-xs font-medium text-blue-600 hover:text-blue-500">
                        Create an Account
                      </a>
                    </Link>
                  </p>
                </div>
              </div>

              <div className="mt-8">
                <CookieWarning />
              </div>
            </div>
          </div>
          <div className="relative flex-1 hidden w-0 lg:block">
            <img
              className="absolute inset-0 object-cover w-full h-full"
              src="https://images.unsplash.com/photo-1540808450974-f61b32d7db7d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
              alt=""
            />
          </div>
        </div>
      </SessionMessageWrapper>
    </AppRedirectionWrapper>
  );
}
